import React, {Component} from "react";
import { Btn } from "components/shared";

export class ServiceHubFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValue: "",
            checkboxDisabled: false,
            selectAll: "",
            flattenValues: "",
            isAUNOChecked: false,
            isBRSPChecked: false,
            isCNBEChecked: false,
            isDEPFChecked: false,
            isMXMEChecked: false,
            isPHCAChecked: false,
            isUSAMChecked: false,
            isAllChecked: false
        };
        this.field = this.props.field;
        this.valueGetter = this.props.valueGetter;

        this.onChange = this.onChange.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
    }

    isFilterActive() {
      return this.state.selectedValue && this.state.selectAll !== '*';
    }

    doesFilterPass(params) {
        return true;
    }

    removeA(arr) {
      var what, a = arguments, L = a.length, ax;
      while (L > 1 && arr.length) {
          what = a[--L];
          while ((ax= arr.indexOf(what)) !== -1) {
              arr.splice(ax, 1);
          }
      }
      return arr;
  }

    getModel() {
        return {value: this.state.selectedValue};
    }

    setModel(model) {
        let newValue = model ? model.value : "";
        this.setState({
            selectedValue: newValue
        });
    }

    onChange(event) {

      if(event.target.value === "*"){
        // eslint-disable-next-line
        this.state.isAllChecked = event.target.checked;
      }
      if(event.target.value === "AUNO"){
        // eslint-disable-next-line
        this.state.isAUNOChecked = event.target.checked;
      }
      if(event.target.value === "BRSP"){
        // eslint-disable-next-line
        this.state.isBRSPChecked = event.target.checked;
      }
      if(event.target.value === "CNBE"){
        // eslint-disable-next-line
        this.state.isCNBEChecked = event.target.checked;
      }
      if(event.target.value === "DEPF"){
        // eslint-disable-next-line
        this.state.isDEPFChecked = event.target.checked;
      }
      if(event.target.value === "MXME"){
        // eslint-disable-next-line
        this.state.isMXMEChecked = event.target.checked;
      }
      if(event.target.value === "PHCA"){
        // eslint-disable-next-line
        this.state.isPHCAChecked = event.target.checked;
      }
      if(event.target.value === "USAM"){
        // eslint-disable-next-line
        this.state.isUSAMChecked = event.target.checked;
      }

      if(event.target.value === "*")
      {
        if(event.target.checked)
        {
          this.setState({
            checkboxDisabled:true,
            selectAll:"*"
          });
        }
        else{
          var selValArray = this.state.selectedValue.split(",");
          this.removeA(selValArray,"*");
          this.setState({
            checkboxDisabled:false,
            selectAll:"",
            selectedValue:selValArray.join(",")
          });
        }
      }
      else{
        const { selectedValue } = this.state;
        if(event.target.checked)
        {
          var selValue = selectedValue + ","+event.target.value;
          this.setState({
            selectedValue: selValue
          })
        }
        else{
          var selValArraySelect = selectedValue.split(",");
          this.removeA(selValArraySelect,event.target.value);
          this.setState({
            selectedValue: selValArraySelect.join(",")
          })
        }
      }
    }

    saveFilter(){
      const { selectAll, selectedValue }= this.state;
      if(selectAll){
          this.setState({
            selectedValue: selectAll,
        }, () => {
            this.props.filterChangedCallback(true);
        });
      }
      else{
        var selValArray = selectedValue.split(",");
          this.removeA(selValArray,"*");
          this.setState({
            selectedValue: selValArray.join(",")
        }, () => {
            this.props.filterChangedCallback(true);
        });
      }
    }

    determineSelectedValue(){
      var selValArray = this.state.selectedValue.split(',');

      for(var i = 0;i < selValArray.length; i++){
        if(selValArray[i] !== "")
        {
          switch(selValArray[i]){
            case "*":
              // eslint-disable-next-line
              this.state.isAllChecked=true;
              // eslint-disable-next-line
              this.state.checkboxDisabled=true;
              break;
            case "AUNO":
              // eslint-disable-next-line
              this.state.isAUNOChecked = true;
              break;
            case "BRSP":
              // eslint-disable-next-line
              this.state.isBRSPChecked = true;
              break;
            case "CNBE":
              // eslint-disable-next-line
              this.state.isCNBEChecked = true;
              break;
            case "DEPF":
              // eslint-disable-next-line
              this.state.isDEPFChecked = true;
              break;
            case "MXME":
              // eslint-disable-next-line
              this.state.isMXMEChecked = true;
              break;
            case "PHCA":
              // eslint-disable-next-line
              this.state.isPHCAChecked = true;
              break;
            case "USAM":
              // eslint-disable-next-line
              this.state.isUSAMChecked = true;
              break;
            default:
              // eslint-disable-next-line
              this.state.isAllChecked = true;
              // eslint-disable-next-line
              this.state.checkboxDisabled = true;
          }
        }
      }
    }

    render() {

        let containerStyle = {
            padding: "20px",
            backgroundColor: "black"
        };

        let labelstyle ={
          'padding-top': "20px"
        };

        this.determineSelectedValue();

        return (
            <div style={containerStyle}>
                <span>Selected hub:</span>
                <label style={labelstyle}><input type="checkbox" value="*" onChange={this.onChange} checked={this.state.isAllChecked}/> All </label>
                <label style={labelstyle}><input type="checkbox" value="AUNO" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isAUNOChecked}/> AUNO </label>
                <label style={labelstyle}><input type="checkbox" value="BRSP" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isBRSPChecked}/> BRSP </label>
                <label style={labelstyle}><input type="checkbox" value="CNBE" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isCNBEChecked}/> CNBE </label>
                <label style={labelstyle}><input type="checkbox" value="DEPF" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isDEPFChecked}/> DEPF </label>
                <label style={labelstyle}><input type="checkbox" value="MXME" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isMXMEChecked}/> MXME </label>
                <label style={labelstyle}><input type="checkbox" value="PHCA" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isPHCAChecked}/> PHCA </label>
                <label style={labelstyle}><input type="checkbox" value="USAM" onChange={this.onChange} disabled={this.state.checkboxDisabled} checked={this.state.isUSAMChecked}/> USAM </label>
                <br />
                <Btn onClick={this.saveFilter}>
                        Save
                      </Btn>
            </div>
          );
    }
}
