import React, {Component} from "react";
import {
  Btn,
  ComponentArray,
  PropertyGrid as Grid,
  PropertyGridBody as GridBody,
  PropertyGridHeader as GridHeader,
  PropertyRow as Row,
  PropertyCell as Cell
} from 'components/shared';
import update from 'immutability-helper';
import { svgs } from 'utilities';

export class SoftwareVersionFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValue: "",
            checkboxDisabled: false,
            selectAll: "",
            flattenValues: "",
            softwareVersions: [],
            deletedSoftwareVersions: []
        };
        this.field = this.props.field;
        this.valueGetter = this.props.valueGetter;

        this.saveFilter = this.saveFilter.bind(this);
        this.addSoftwareVersion = this.addSoftwareVersion.bind(this);
        this.deleteSoftwareVersion = this.deleteSoftwareVersion.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    isFilterActive() {
        return this.state.softwareVersions && this.state.softwareVersions.length > 0;
    }

    doesFilterPass(params) {
        return true;
    }

    removeA(arr) {
      var what, a = arguments, L = a.length, ax;
      while (L > 1 && arr.length) {
          what = a[--L];
          while ((ax= arr.indexOf(what)) !== -1) {
              arr.splice(ax, 1);
          }
      }
      return arr;
  }

    getModel() {
        return {value: this.state.flattenValues};
    }

    setModel(model) {
        let newValue = model ? model.value : "";
        this.setState({
          softwareVersions: newValue
        });
    }

    saveFilter(){
      this.filterActive = true;
      var softwareVersionsString = this.state.softwareVersions.join(",");
          this.setState({
            //softwareVersions: this.state.softwareVersions
            flattenValues:softwareVersionsString
        }, () => {
            this.props.filterChangedCallback(true);
        });
    }

    addSoftwareVersion(){
      this.setState({softwareVersions: [...this.state.softwareVersions, ""]})
    };

    deleteSoftwareVersion = (index) =>
    (evt) => {
      // eslint-disable-next-line
      this.state.softwareVersions = this.state.softwareVersions.toString().split(",");
      this.setState(update(this.state, {
        softwareVersions: { $set: this.state.softwareVersions.filter((_, idx) => index !== idx) },
        deletedSoftwareVersions: { $push: [this.state.softwareVersions[index]] }
      }));
    };

    handleChange(e, index){
      // eslint-disable-next-line
      this.state.softwareVersions[index] = e.target.value;
      this.setState({softwareVersions: this.state.softwareVersions});
    }

    render() {

      var { softwareVersions } = this.state;
       if(softwareVersions){
        softwareVersions = softwareVersions.toString().split(",");
      }

        let containerStyle = {
            backgroundColor: "black"
        };

        let buttonStyle ={
          'padding': "20px"
        };

        return (
            <div style={containerStyle}>
            <Grid className="data-grid">
            <GridHeader>
              <Row className="action-row">
                <Btn svg={svgs.plus} onClick={this.addSoftwareVersion}>Add new</Btn>
              </Row>
            </GridHeader>
            <GridBody>
              {
                softwareVersions.length > 0 &&
                softwareVersions.map((softwareversion, idx) =>
                <ComponentArray key={idx}>
                  <Row>
                    <Cell className="col-3">
                    <input type="text" value={softwareversion} onChange={(e) => this.handleChange(e, idx)}/>
                    </Cell>
                    <Cell className="col-1">
                        <Btn className="icon-only-btn" svg={svgs.trash} onClick={this.deleteSoftwareVersion(idx)} />
                    </Cell>
                  </Row>
                </ComponentArray>
                )
              }
              <Btn style={buttonStyle} onClick={this.saveFilter}>
                        Save
                      </Btn>
            </GridBody>
            </Grid>
            </div>
          );
    }
}
