import React, {Component} from "react";
import {
  Btn,
  ComponentArray,
  PropertyGrid as Grid,
  PropertyGridBody as GridBody,
  PropertyGridHeader as GridHeader,
  PropertyRow as Row,
  PropertyCell as Cell
} from 'components/shared';
import update from 'immutability-helper';
import { svgs } from 'utilities';

export class SerialNumberFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValue: "",
            checkboxDisabled: false,
            selectAll: "",
            flattenValues: "",
            serialNumbers: [],
            deletedSerialNumbers: []
        };
        this.field = this.props.field;
        this.valueGetter = this.props.valueGetter;

        this.saveFilter = this.saveFilter.bind(this);
        this.addSerialNumber = this.addSerialNumber.bind(this);
        this.deleteSerialNumber = this.deleteSerialNumber.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    isFilterActive() {
      return this.state.serialNumbers && this.state.serialNumbers.length > 0;
    }

    doesFilterPass(params) {
        return true;
    }

    removeA(arr) {
      var what, a = arguments, L = a.length, ax;
      while (L > 1 && arr.length) {
          what = a[--L];
          while ((ax= arr.indexOf(what)) !== -1) {
              arr.splice(ax, 1);
          }
      }
      return arr;
  }

    getModel() {
        return {value: this.state.flattenValues};
    }

    setModel(model) {
        let newValue = model ? model.value : "";
        this.setState({
          serialNumbers: newValue
        });
    }

    saveFilter(){
      var serialNumbersString = this.state.serialNumbers.join(",");
          this.setState({
            //serialNumbers: this.state.serialNumbers
            flattenValues:serialNumbersString
        }, () => {
            this.props.filterChangedCallback();
        });
    }

    addSerialNumber(){
      this.setState({serialNumbers: [...this.state.serialNumbers, ""]})
    };

    deleteSerialNumber = (index) =>
    (evt) => {
      // eslint-disable-next-line
      this.state.serialNumbers = this.state.serialNumbers.toString().split(",");
      this.setState(update(this.state, {
        serialNumbers: { $set: this.state.serialNumbers.filter((_, idx) => index !== idx) },
        deletedSerialNumbers: { $push: [this.state.serialNumbers[index]] }
      }));
    };

    handleChange(e, index){
      // eslint-disable-next-line
      this.state.serialNumbers[index] = e.target.value;
      this.setState({serialNumbers: this.state.serialNumbers});
    }

    render() {

      var { serialNumbers } = this.state;
       if(serialNumbers){
        serialNumbers = serialNumbers.toString().split(",");
      }

        let containerStyle = {
            backgroundColor: "black"
        };

        let buttonStyle ={
          'padding': "20px"
        };

        return (
            <div style={containerStyle}>
            <Grid className="data-grid">
            <GridHeader>
              <Row className="action-row">
                <Btn svg={svgs.plus} onClick={this.addSerialNumber}>Add new</Btn>
              </Row>
            </GridHeader>
            <GridBody>
              {
                serialNumbers.length > 0 &&
                serialNumbers.map((serialnumber, idx) =>
                <ComponentArray key={idx}>
                  <Row>
                    <Cell className="col-3">
                    <input type="text" value={serialnumber} onChange={(e) => this.handleChange(e, idx)}/>
                    </Cell>
                    <Cell className="col-1">
                        <Btn className="icon-only-btn" svg={svgs.trash} onClick={this.deleteSerialNumber(idx)} />
                    </Cell>
                  </Row>
                </ComponentArray>
                )
              }
              <Btn style={buttonStyle} onClick={this.saveFilter}>
                        Save
                      </Btn>
            </GridBody>
            </Grid>
            </div>
          );
    }
}
